<template>
  <section class="layout-default-container">
    <ClientOnly>
      <LazyLayoutOverlays v-if="overlayData.length" :overlays="overlayData" />
      <FirstElementContainer />
    </ClientOnly>

    <LayoutHeader
      :config="layoutContent?.config"
      :header="layoutContent?.header"
      :is-logged-in="undefined"
    />

    <ErrorBoundary>
      <main class="page-container">
        <slot />
      </main>
      <template #error="{ error }">
        <Error :status-code="error" />
      </template>
    </ErrorBoundary>

    <LayoutFooter v-bind="layoutContent?.footer" />

    <UiZendeskWedWidget
      v-if="layoutContent?.config?.zendeskKey"
      class="is-hidden"
      :aria-label="$t('layout.header.help')"
      :widget-key="layoutContent?.config?.zendeskKey"
    />
  </section>
</template>

<script lang="ts" setup>
import { useQuery } from '@tanstack/vue-query'
import type { LayoutEntrySeoDTO } from '~/types/api/bff/web/contentful/seo.types'
import UiZendeskWedWidget from '@sephora-asia/ui-zendesk-web-widget'
import type { OverlayDTO } from '~/types/api/bff/web/contentful/component.types'

const { data: layoutContent, suspense } = useQuery(useGetLayoutOptions())

const seo: ComputedRef<LayoutEntrySeoDTO> = computed(
  () => layoutContent?.value?.seo || {}
)

const overlayData: ComputedRef<OverlayDTO[]> = computed(
  () => layoutContent.value?.overlays || []
)

const googleOneTapClientId = layoutContent.value?.config?.googleOneTapClientId

useLayoutSeoMeta(seo)

const { isLoggedIn } = await useCurrentUser()

if (googleOneTapClientId) {
  useGoogleOneTapLogin(googleOneTapClientId, isLoggedIn)
}

await suspense()
</script>

<style lang="scss" scoped>
.layout-default-container {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
}
.page-container {
  --page-container-margin-bottom: 64px;
  flex-grow: 1;
  margin-bottom: var(--page-container-margin-bottom);

  @include mobile {
    --page-container-margin-bottom: 24px;
  }
}
</style>
