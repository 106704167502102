<template>
  <slot v-if="!error" />
  <div v-else class="error-container">
    <slot name="error" :error="error" :clear-error="clearBoundaryError" />
  </div>
</template>

<script setup lang="ts">
const clientSideError = ref<Error>()
const serverSideError = useError()

const error = computed(() => {
  if (clientSideError.value || serverSideError.value) {
    return (
      clientSideError.value?.message ||
      serverSideError.value?.statusCode.toString()
    )
  }
  return undefined
})

function clearBoundaryError() {
  clientSideError.value = undefined
}

onErrorCaptured(err => {
  clientSideError.value = err
  return false
})

const router = useRouter()

watch(
  () => router.currentRoute.value,
  () => {
    clientSideError.value = undefined
  }
)
</script>

<style lang="scss" scoped>
.error-container {
  flex-grow: 1;
  background-color: var(--color-base-grey-05);
}
</style>
